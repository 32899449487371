﻿import html2canvas from 'html2canvas';
import { icon } from 'leaflet';

export default class LeafletMapToImage {
    constructor() {
        this._map = null;
    }

    //createMap() {
    //    let mymap = L.map('mymap').setView([51.505, -0.09], 13);

    //    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //    }).addTo(mymap);

    //    L.marker([51.5, -0.09]).addTo(mymap);

    //    L.circle([51.508, -0.11], {
    //        color: 'red',
    //        fillColor: '#f03',
    //        fillOpacity: 0.5,
    //        radius: 500
    //    }).addTo(mymap);

    //    L.polygon([
    //        [51.509, -0.08],
    //        [51.503, -0.06],
    //        [51.51, -0.047]
    //    ]).addTo(mymap);

    //    this._map = mymap;
    //}

    async Generate(map, scale = 1) {
        let self = this;

        self._map = map;
        self.tilesImgs = {};
        self.path = {};
        self.circles = {};

        self.markers = {};
        self.textMarkers = {};
        self.imageMarkers = {};

        self.icons = {};
        self.iconBlobUrls = {};

        let dimensions = self._map.getSize();

        self.zoom = self._map.getZoom();
        self.bounds = self._map.getPixelBounds();

        self.canvas = document.createElement('canvas');
        self.canvas.width = dimensions.x;
        self.canvas.height = dimensions.y;
        self.ctx = self.canvas.getContext('2d');

        this._changeScale(scale);

        await self._fetchIcons();
        await self._renderToCanvas();
       
        var imageData = self.canvas.toDataURL();

        let blob = new Blob([imageData]);
        let byteArray = await blob.arrayBuffer();
        return byteArray;
    }

    async _fetchIcons() {
        let self = this;
        let promise = new Promise(async function (resolve, reject) {
            await self.fetchIcons(resolve);
        });
        return await promise;
    }

    async fetchIcons(resolve) {
        let self = this;
        let promises = [];

        self._map.eachLayer(async function (layer) {
            promises.push(new Promise(async (new_resolve) => {
                try {
                    if (layer instanceof L.Marker && layer.options.iconName) {
                        await self.setIcons(self, layer, new_resolve)
                    } else {
                        new_resolve();
                    }
                } catch (e) {
                    console.log(e);
                    new_resolve();
                }
            }));
        });

        Promise.all(promises).then(() => {
            resolve()
        });
    }

    async setIcons(self, layer, new_resolve) {

        let iconName = layer.options.iconName || 'location_on';

        if (!self.icons[iconName]) {
            self.icons[iconName] = "pending";
            self.icons[iconName] = await self.fetchSvg(iconName, new_resolve);
        } else {
            new_resolve();
        }
    }

    async fetchSvg(iconName, resolve, maxVersion = 32, size = 24) {

        let first = true;
        let foundVersion = 0;
        let step = maxVersion;

        let version = maxVersion;
        while (step > 0) {
            let url = `https://fonts.gstatic.com/s/i/materialicons/${iconName}/v${version}/${size}px.svg`;

            try {
                step = Math.floor(step / 2);

                const response = await fetch(url);

                if (response.ok) {

                    foundVersion = version;

                    if (first || step == 0) {
                        const svgText = await response.text();
                        resolve();
                        return svgText;
                    }

                    version += step;

                } else {
                    if (foundVersion != 0 && step == 0) {
                        url = `https://fonts.gstatic.com/s/i/materialicons/${iconName}/v${foundVersion}/${size}px.svg`;
                        const response = await fetch(url);
                        const svgText = await response.text();
                        resolve();
                        return svgText;
                    } else {
                        foundVersion == 0 ? version -= step : version += step;
                    }
                }

                first = false;

            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
                resolve();
            }

        }

        console.error('Not possible to fetch icon with name: ' + iconName);
        resolve();
    }

    async _renderToCanvas() {
        let self = this;

        let promise = new Promise(function (resolve, reject) {
            self._getLayers(resolve);
        });

        promise.then(() => {
            return new Promise(((resolve, reject) => {
                for (const [key, value] of Object.entries(self.tilesImgs)) {
                    self.ctx.drawImage(value.img, value.x, value.y, self.tileSize, self.tileSize);
                }
                for (const [key, value] of Object.entries(self.path)) {
                    self._drawPath(value);
                }
                for (const [key, value] of Object.entries(self.markers)) {
                    self.ctx.drawImage(value.img, value.x, value.y); 
                }
                for (const [key, value] of Object.entries(self.textMarkers)) {
                    self.ctx.font = '12px / 1.5 "Helvetica Neue", Arial, Helvetica, sans-serif';
                    self.ctx.fillStyle = 'black';
                    self.ctx.fillText(value.text, value.x, value.y); 
                }
                for (const [key, value] of Object.entries(self.imageMarkers)) {
                    self.ctx.drawImage(value.img, value.x, value.y, value.width, value.height); 
                }
                for (const [key, value] of Object.entries(self.circles)) {
                    //self._drawCircle(value);
                }
                resolve();
            }));
        });

        return await promise;
    }

    _getLayers(resolve) {
        let self = this;
        let promises = [];

        self._map.eachLayer(function (layer) {
            promises.push(new Promise((new_resolve) => {
                try {
                    if (layer instanceof L.Marker) {
                        self._getMarkerLayer(layer, new_resolve);
                    } else if (layer instanceof L.TileLayer) {
                        self._getTileLayer(layer, new_resolve);
                    } else if (layer instanceof L.Circle) {
                        if (!self.circles[layer._leaflet_id]) {
                            self.circles[layer._leaflet_id] = layer;
                        }
                        new_resolve();
                    } else if (layer instanceof L.Path) {
                        self._getPathLayer(layer, new_resolve);
                    } else {
                        new_resolve();
                    }
                } catch (e) {
                    console.log(e);
                    new_resolve();
                }
            }));
        });

        Promise.all(promises).then(() => {
            resolve()
        });
    }

    _getTileLayer(layer, resolve) {
        let self = this;

        self.tiles = [];
        self.tileSize = layer._tileSize.x;
        self.tileBounds = L.bounds(self.bounds.min.divideBy(self.tileSize)._floor(), self.bounds.max.divideBy(self.tileSize)._floor());

        for (let j = self.tileBounds.min.y; j <= self.tileBounds.max.y; j++)
            for (let i = self.tileBounds.min.x; i <= self.tileBounds.max.x; i++)
                self.tiles.push(new L.Point(i, j));

        let promiseArray = [];
        self.tiles.forEach(tilePoint => {
            let originalTilePoint = tilePoint.clone();
            if (layer._adjustTilePoint) layer._adjustTilePoint(tilePoint);

            let tilePos = originalTilePoint.scaleBy(new L.Point(self.tileSize, self.tileSize)).subtract(self.bounds.min);

            if (tilePoint.y < 0) return;

            promiseArray.push(new Promise(resolve => {
                self._loadTile(tilePoint, tilePos, layer, resolve);
            }));
        });

        Promise.all(promiseArray).then(() => {
            resolve();
        });
    }

    _getMarkerLayer(layer, resolve) {

        let self = this;

        if (self.markers[layer._leaflet_id] || self.textMarkers[layer._leaflet_id] || self.imageMarkers[layer._leaflet_id]) {
            resolve();
            return;
        }

        let pixelPoint = self._getPixelPoint(self, layer);

        if (!self._pointPositionIsNotCorrect(pixelPoint)) {
            if (layer.options.iconName) {
                let iconName = layer.options.iconName || 'location_on';
                const svg = self.icons[iconName];
                const coloredSvg = this.colorizeSvg(svg, layer.options.color);
                const url = self._getBlobUrl(self, iconName, coloredSvg);
                const img = new Image();
                img.src = url;
                img.onload = function () {
                    self.markers[layer._leaflet_id] = {
                        img: img,
                        x: pixelPoint.x,
                        y: pixelPoint.y
                    };
                    URL.revokeObjectURL(url);
                    resolve();
                };
            } else if (layer.options.text) {
                self.textMarkers[layer._leaflet_id] = {
                    text: layer.options.text,
                    x: pixelPoint.x,
                    y: pixelPoint.y
                };
                resolve();
            } else if (layer.options.image) {
                try {
                    if (layer instanceof L.Marker && layer.options.image) {
                        self._getImageMarker(pixelPoint, layer, resolve)
                    } else {
                        resolve();
                    }
                } catch (e) {
                    console.log(e);
                    resolve();
                }
            } else {
                resolve();
            }
        } else {
            resolve();
        }
    }

    _getBlobUrl(self, iconName, coloredSvg) {
        if (!self.iconBlobUrls[iconName]) {
            const svgBlob = new Blob([coloredSvg], { type: 'image/svg+xml;charset=utf-8' });
            const url = URL.createObjectURL(svgBlob);
            self.iconBlobUrls[iconName] = url;
        }

        return self.iconBlobUrls[iconName];

    }

    _loadTile(tilePoint, tilePos, layer, resolve) {
        let self = this;
        let imgIndex = tilePoint.x + ':' + tilePoint.y + ':' + self.zoom;
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function () {
            if (!self.tilesImgs[imgIndex]) self.tilesImgs[imgIndex] = { img: image, x: tilePos.x, y: tilePos.y };
            resolve();
        };
        image.src = layer.getTileUrl(tilePoint);
    }

    _getPixelPoint(self, layer) {
        let pixelPoint = self._map.project(layer._latlng);
        pixelPoint = pixelPoint.subtract(new L.Point(self.bounds.min.x, self.bounds.min.y));

        if (layer.options.icon && layer.options.icon.options && layer.options.icon.options.iconAnchor) {
            pixelPoint.x -= layer.options.icon.options.iconAnchor[0];
            pixelPoint.y -= layer.options.icon.options.iconAnchor[1];
        }
        return pixelPoint;
    }

    _getImageMarker(pixelPoint, layer, resolve) {
        let self = this;
        let image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = layer.options.image;
        image.onload = function () {
            if (!self.imageMarkers[layer._leaflet_id] && layer.options.imageSize)
                self.imageMarkers[layer._leaflet_id] = { img: image, x: pixelPoint.x, y: pixelPoint.y, width: layer.options.imageSize[0], height: layer.options.imageSize[1] };
            resolve();
        };
    }

    _pointPositionIsNotCorrect(point) {
        return (point.x < 0 || point.y < 0 || point.x > this.canvas.width || point.y > this.canvas.height);
    }

    _getPathLayer(layer, resolve) {
        let self = this;

        let correct = 0;
        let parts = [];

        if (layer._mRadius || !layer._latlngs) {
            resolve();
            return;
        }

        let type = layer.feature.geometry.type; //"Polygon", "MultiPolygon"

        let latlngs = layer.options.fill ? layer._latlngs[0] : layer._latlngs;
        
        if (type === "MultiPolygon") {
            latlngs.forEach((multi) => {
                multi.forEach((latLng) => {
                    let pixelPoint = self._map.project(latLng);
                    pixelPoint = pixelPoint.subtract(new L.Point(self.bounds.min.x, self.bounds.min.y));
                    parts.push(pixelPoint);
                    if (pixelPoint.x < self.canvas.width && pixelPoint.y < self.canvas.height) correct = 1;
                })
            });
        }
        else {
            latlngs.forEach((latLng) => {
                let pixelPoint = self._map.project(latLng);
                pixelPoint = pixelPoint.subtract(new L.Point(self.bounds.min.x, self.bounds.min.y));
                parts.push(pixelPoint);
                if (pixelPoint.x < self.canvas.width && pixelPoint.y < self.canvas.height) correct = 1;
            });
        }

        if (correct) self.path[layer._leaflet_id] = {
            parts: parts,
            closed: layer.options.fill,
            options: layer.options
        };

        function _pushPoints(latLng) {
            let pixelPoint = self._map.project(latLng);
            pixelPoint = pixelPoint.subtract(new L.Point(self.bounds.min.x, self.bounds.min.y));
            parts.push(pixelPoint);
            if (pixelPoint.x < self.canvas.width && pixelPoint.y < self.canvas.height) correct = 1;
        }

        resolve();
    }

    _changeScale(scale) {
        if (!scale || scale <= 1) return 0;

        let addX = (this.bounds.max.x - this.bounds.min.x) / 2 * (scale - 1);
        let addY = (this.bounds.max.y - this.bounds.min.y) / 2 * (scale - 1);

        this.bounds.min.x -= addX;
        this.bounds.min.y -= addY;
        this.bounds.max.x += addX;
        this.bounds.max.y += addY;

        this.canvas.width *= scale;
        this.canvas.height *= scale;
    }

    _drawPath(value) {
        let self = this;

        self.ctx.beginPath();
        let count = 0;
        let options = value.options;
        value.parts.forEach((point) => {
            self.ctx[count++ ? 'lineTo' : 'moveTo'](point.x, point.y);
        });

        if (value.closed) self.ctx.closePath();

        this._feelPath(options);
    }

    _drawCircle(layer, resolve) {

        if (layer._empty()) {
            return;
        }

        let point = this._map.project(layer._latlng);
        point = point.subtract(new L.Point(this.bounds.min.x, this.bounds.min.y));

        let r = Math.max(Math.round(layer._radius), 1),
            s = (Math.max(Math.round(layer._radiusY), 1) || r) / r;

        if (s !== 1) {
            this.ctx.save();
            this.scale(1, s);
        }

        this.ctx.beginPath();
        this.ctx.arc(point.x, point.y / s, r, 0, Math.PI * 2, false);

        if (s !== 1) {
            this.ctx.restore();
        }

        this._feelPath(layer.options);
    }

    _feelPath(options) {

        if (options.fill) {
            this.ctx.globalAlpha = options.fillOpacity;
            this.ctx.fillStyle = options.fillColor || options.color;
            this.ctx.fill(options.fillRule || 'evenodd');
        }

        if (options.stroke && options.weight !== 0) {
            if (this.ctx.setLineDash) {
                this.ctx.setLineDash(options && options._dashArray || []);
            }
            this.ctx.globalAlpha = options.opacity;
            this.ctx.lineWidth = options.weight;
            this.ctx.strokeStyle = options.color;
            this.ctx.lineCap = options.lineCap;
            this.ctx.lineJoin = options.lineJoin;
            this.ctx.stroke();
        }
    }

    colorizeSvg(svgText, color) {
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
        const svgElement = svgDoc.querySelector('svg');
        svgElement.setAttribute('fill', color);
        return new XMLSerializer().serializeToString(svgElement);
    }
}
